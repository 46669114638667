import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom'; 
import hexa from './imagenes/HexaPerfiles.svg'

const SidebarMobile = ({ setFiltro, filtro, deembys,setFiltroMr,filtroMr }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const navigate = useNavigate(); 
  const handleCollapseToggle = () => setCollapseOpen(!collapseOpen);

  const deembyReturn = () => {
    window.location.href = 'https://deemby.org';
  }

  const handleOptionClick = (filtroId,filtro) => {
    setFiltro(filtroId);
    setFiltroMr(filtro);
    setCollapseOpen(false);
  };


  return (
    <div>
      <div>
        <div style={styles.option}>
          <div style={styles.optionText} onClick={() => handleOptionClick(0)}>
            <p style={styles.optionLabel}>Home</p> <p style={styles.optionLabel}>Top 10</p> <p style={{...styles.optionLabel,paddingRight:'10%'}}>Hive</p>
          </div>
        </div>
        <div style={{...styles.option, backgroundColor: filtro === 1 && filtroMr===0 ? '#000000' : 'white', color: filtro === 1 && filtroMr===0? 'white' : 'black'}} 
        onClick={() => handleOptionClick(1,0)}>
          <div style={styles.optionText2}>
            <p style={styles.optionLabel}>Tus Gobernantes</p>
            <div style={{ position: 'relative', ...styles.notification }}>
            <img src={hexa}/>
            <span style={{
              position: 'absolute',
              right: '-0%', // Ajusta este valor según sea necesario para la posición horizontal
              top: '10%', // Ajusta este valor según sea necesario para la posición horizontal
              color: 'white', // Color del texto del número
              borderRadius: '50%', // Forma redondeada para que sea un círculo
              width: '20px', // Ancho del número
              height: '20px', // Alto del número
              textAlign: 'center', // Alineación del texto al centro
              lineHeight: '20px', // Altura de línea igual a la altura del número para centrar verticalmente
              fontSize: '12px', // Tamaño de fuente del número
            }}>
              {deembys[0] && deembys[0].cantidad_registros > 0 ? 0 : 1}
            </span>
          </div>
          </div>
        </div>
        <div style={{...styles.option,paddingLeft:'30px', backgroundColor: filtro === 1 && filtroMr===1 ? '#000000' : 'white', color: filtro === 1&& filtroMr===1 ? 'white' : 'black'}}
       onClick={() => handleOptionClick(1,1)}>
          <div style={styles.optionText2}>
            <p style={styles.optionLabel}>Presidencia </p>
          </div>
        </div>
        <div style={{...styles.option,paddingLeft:'30px', backgroundColor: filtro === 1 && filtroMr===2 ? '#000000' : 'white', color: filtro === 1&& filtroMr===2 ? 'white' : 'black'}}
      onClick={() => handleOptionClick(1,2)}>
          <div style={styles.optionText2}>
            <p style={styles.optionLabel}>Gubernatura </p>
          </div>
        </div>
        <div style={{...styles.option,paddingLeft:'30px', backgroundColor: filtro === 1 && filtroMr===3 ? '#000000' : 'white', color: filtro === 1&& filtroMr===3 ? 'white' : 'black'}}
      onClick={() => handleOptionClick(1,3)}>
          <div style={styles.optionText2}>
            <p style={styles.optionLabel}>Alcaldía CDMX </p>
          </div>
        </div>
        
        <div style={{...styles.option, backgroundColor: filtro === 2 && filtroMr===0 ? '#000000' : 'white', color: filtro === 2 && filtroMr===0? 'white' : 'black'}} 
      onClick={() => handleOptionClick(2,0)}>
          <div style={styles.optionText2}>
            <p style={styles.optionLabel}>Tus Representantes</p>
            <div style={{ position: 'relative', ...styles.notification }}>
            <img src={hexa}/>
            <span style={{
              position: 'absolute',
              right: '-0%', // Ajusta este valor según sea necesario para la posición horizontal
              top: '10%', // Ajusta este valor según sea necesario para la posición horizontal
              color: 'white', // Color del texto del número
              borderRadius: '50%', // Forma redondeada para que sea un círculo
              width: '20px', // Ancho del número
              height: '20px', // Alto del número
              textAlign: 'center', // Alineación del texto al centro
              lineHeight: '20px', // Altura de línea igual a la altura del número para centrar verticalmente
              fontSize: '12px', // Tamaño de fuente del número
            }}>
              {deembys[1] && deembys[1].cantidad_registros > 0 ? 0 : 1}
            </span>
          </div>
          </div>
        </div>
        <div style={{...styles.option,paddingLeft:'30px', backgroundColor: filtro === 2 && filtroMr===4 ? '#000000' : 'white', color: filtro === 2&& filtroMr===4 ? 'white' : 'black'}}
        onClick={() => handleOptionClick(2,4)}>
          <div style={styles.optionText2}>
            <p style={styles.optionLabel}>Diputaciones Federales </p>
          </div>
        </div>
        <div style={{...styles.option,paddingLeft:'30px', backgroundColor: filtro === 2 && filtroMr===5 ? '#000000' : 'white', color: filtro === 2&& filtroMr===5 ? 'white' : 'black'}}
      onClick={() => handleOptionClick(2,5)}>
          <div style={styles.optionText2}>
            <p style={styles.optionLabel}>Senadurías </p>
          </div>
        </div>


        <div style={{...styles.option, backgroundColor: filtro === 3 && filtroMr===0 ? '#000000' : 'white', color: filtro === 3 && filtroMr===0? 'white' : 'black'}} 
      onClick={() => handleOptionClick(3,0)}>
          <div style={styles.optionText2}>
            <p style={styles.optionLabel}>Tus Ministr@s </p>
            <div style={{ position: 'relative', ...styles.notification }}>
            <img src={hexa}/>
            <span style={{
              position: 'absolute',
              right: '-0%', // Ajusta este valor según sea necesario para la posición horizontal
              top: '10%', // Ajusta este valor según sea necesario para la posición horizontal
              color: 'white', // Color del texto del número
              borderRadius: '50%', // Forma redondeada para que sea un círculo
              width: '20px', // Ancho del número
              height: '20px', // Alto del número
              textAlign: 'center', // Alineación del texto al centro
              lineHeight: '20px', // Altura de línea igual a la altura del número para centrar verticalmente
              fontSize: '12px', // Tamaño de fuente del número
            }}>
              {deembys[2] && deembys[2].cantidad_registros > 0 ? 0 : 1}
            </span>
          </div>
          </div>
        </div>
        <div style={{...styles.option,paddingLeft:'30px', backgroundColor: filtro === 3 && filtroMr===6 ? '#000000' : 'white', color: filtro === 3 && filtroMr===6 ? 'white' : 'black'}}
        onClick={() => handleOptionClick(3,6)}>
          <div style={styles.optionText2}>
            <p style={styles.optionLabel}>Ministr@s </p>
          </div>
        </div>

        <div style={{...styles.option, backgroundColor: filtro === 4 && filtroMr===0 ? '#000000' : 'white', color: filtro === 4 && filtroMr===0? 'white' : 'black'}} 
        onClick={() => handleOptionClick(4,0)}>
          <div style={styles.optionText2}>
            <p style={styles.optionLabel}>Tus Secretari@s </p>
            <div style={{ position: 'relative', ...styles.notification }}>
            <img src={hexa}/>
            <span style={{
              position: 'absolute',
              right: '-0%', // Ajusta este valor según sea necesario para la posición horizontal
              top: '10%', // Ajusta este valor según sea necesario para la posición horizontal
              color: 'white', // Color del texto del número
              borderRadius: '50%', // Forma redondeada para que sea un círculo
              width: '20px', // Ancho del número
              height: '20px', // Alto del número
              textAlign: 'center', // Alineación del texto al centro
              lineHeight: '20px', // Altura de línea igual a la altura del número para centrar verticalmente
              fontSize: '12px', // Tamaño de fuente del número
            }}>
              {deembys[3] && deembys[3].cantidad_registros > 0 ? 0 : 1}
            </span>
          </div>
          </div>
        </div>
        <div style={{...styles.option,paddingLeft:'30px', backgroundColor: filtro === 4 && filtroMr===7 ? '#000000' : 'white', color: filtro === 4 && filtroMr===7 ? 'white' : 'black'}}
        onClick={() => handleOptionClick(4,7)}>
          <div style={styles.optionText2}>
            <p style={styles.optionLabel}>Secretarías </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  sidebar: {
    backgroundColor: 'white',
    padding: '20px',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px', 
    cursor:'pointer'
  },
  logoContainer: {
    marginRight: '10px',
    textAlign: 'center',
    color:'black' 
  },
  logo: {
    width: '30px',
    height: '30px',
  },
  optionText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft:'2%',
    width: '100%',
    cursor:'pointer'
  },
  optionText2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  optionLabel: {
    fontSize: '14px', 
    margin: 0, 
  },
  notification: {
    width: '20px',
    height: '20px',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '20px',
    marginRight: '45%',
  },
  notificationNumber: {
    fontSize: '11px', // Tamaño de fuente reducido para el número de notificación
  },
};

export default SidebarMobile;
