import React, { useState, useEffect } from 'react';

export const CircleIcon = ({ color = "#000000" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <circle cx="6" cy="6" r="6" fill={color} />
  </svg>
);

const Filtro = ({ setFiltroMr, setPartido,filtroMr }) => {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const [partidos, setPartidos] = useState(false);

  const handleChange = (event) => {
    setPartido(event.target.value);
  };
 
  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    setFiltroMr(filter);
    if (filter === '2') {
      setShowSelect(true);
    } else {
      setShowSelect(false);
    }
  };

  useEffect(() => {
    handleFilterSelect('1'); // Selecciona automáticamente la opción 'Mayoria Relativa'
    getPartidosCoalicion(); // Llama a la función para obtener los partidos
  }, []);

  const getPartidosCoalicion = () => {
    const data = { 'type': 'GetPatidos',filtroMr }
    fetch('https://novomediaapp.azurewebsites.net/InfoGobiernoProv.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        if(data){
          setPartidos(data.data);
          setPartido(data.data[0].value);
        }
        
      })
      .catch(error => console.error('Error de red:', error));
  }

  return (
    <div>
      {<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <label
          style={{
            position: 'relative',
            borderRadius: '20px',
            border: `2px solid ${selectedFilter === '1' ? '#28a745' : '#ddd'}`,
            padding: '10px 20px',
            margin: '0 10px',
            backgroundColor: 'transparent',
            outline: 'none',
            cursor: 'pointer',
          }}
          onClick={() => handleFilterSelect('1')}
        >
          <input
            style={{ display: 'none' }}
            type="checkbox"
            checked={selectedFilter === '1'}
            onChange={() => handleFilterSelect('1')}
          />
          <span style={{ position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)' }}>
            <CircleIcon color={selectedFilter === '1' ? '#28a745' : 'transparent'} />
          </span>
          Mayoría Relativa
        </label>

        <label
          style={{
            position: 'relative',
            borderRadius: '20px',
            border: `2px solid ${selectedFilter === '2' ? '#28a745' : '#ddd'}`, // Borde verde cuando seleccionado, de lo contrario, borde gris
            padding: '10px 20px',
            margin: '0 10px',
            backgroundColor: 'transparent', // Fondo transparente
            outline: 'none',
            cursor: 'pointer',
          }}
          onClick={() => handleFilterSelect('2')}
        >
          <input
            style={{ display: 'none' }}
            type="checkbox"
            checked={selectedFilter === '2'}
            onChange={() => handleFilterSelect('2')}
          />
          <span style={{ position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)' }}>
            <CircleIcon color={selectedFilter === '2' ? '#28a745' : 'transparent'} />
          </span>
          Representación Proporcional
        </label>
      </div>
      }
      {showSelect && (
        <div>
          <select className='form-control' onChange={handleChange}>
            {partidos && partidos.map((partido) => (
              <option key={partido.value} value={partido.value}>{partido.label}</option>
            ))}
          </select><br />
        </div>
      )}
    </div>
  );
};

export default Filtro;
