import React, { useState } from 'react';
import infogobierno from './imagenes/infogobierno.png';
import { useNavigate } from 'react-router-dom'; 
import messengerIcon from './imagenes/message.svg';
import plusIcon from './imagenes/UbicaTuCasilla.svg';
import searchIcon from './imagenes/serach.svg';


const Navbar = ({setcandidato,isDesktop}) => {
  const navigate = useNavigate(); 
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [candidatosSerch, setCandidatosSerch] = useState([]);
  const user_id = localStorage.getItem('id_user');

  const handleSearchClick = () => {
    setShowSearchInput(!showSearchInput);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const data = {'type':'GetCandidatosSearch','texto':searchQuery,user_id}
      fetch('https://novomediaapp.azurewebsites.net/InfoGobiernoProv.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => {
        setCandidatosSerch(data.data);
      })
      .catch(error => console.error('Error de red:', error));
    }  
  };

  const handleProfileClick = (candidato) => {
    setcandidato(candidato);
    setSearchQuery('');
    setCandidatosSerch([]);
    setShowSearchInput(false);
    navigate(`/Candidato`);
  };

  const deembyReturn = () => {
    window.location.href = 'https://deemby.org';
  }

  return (
    <>
            <div style={styles.navbar}>
        <div style={styles.logoContainer}>
          <img src={infogobierno} alt="InfoGobierno" style={{...styles.logo,width:isDesktop?'220px':'80%',height:'auto'}} onClick={deembyReturn}/>
        </div>
        <div style={styles.navbarItems}>
          <img src={messengerIcon} style={{width:'25px',marginRight:'15%'}} onClick={() => navigate('/Message')}/>
          <img src={searchIcon} style={{width:'25px'}} onClick={handleSearchClick}/>
        </div>
      </div>
      <div style={styles.navbar}>
        <div style={styles.logoContainer}>
          
        </div>
        <div style={styles.navbarItems}>
          {/*<FaPlus style={styles.icon} onClick={() => navigate('/NewPost')}/>
          <img src={Deemby} alt="InfoGobierno" style={{...styles.logo,width:'85px',height:'85px'}} onClick={() => deembyReturn()}/>
          */}
          
          {showSearchInput && (
            <div style={styles.inputSearch}>
              <input
                type="text"
                placeholder="Buscar Conversaciones"
                style={styles.searchInput}
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyPress={handleKeyPress}
              />
              <div style={styles.candidatosContainer}>
                <div style={styles.candidatosList}>
                  {candidatosSerch.map((candidato) => (
                    <div key={candidato.usuario_id} onClick={() => handleProfileClick(candidato)} style={styles.candidatoContainer}>
                      <img src={candidato.foto} alt="Foto de perfil" style={styles.fotoPerfil} />
                      <span style={styles.nombre}>{candidato.nombre}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const styles = {
  navbar: {
    color: '#5D7CF9', // Cambia el color de los iconos
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 20px',
  },
  logoContainer: {
    flex: 1,
  },
  logo: {
    height: '30px',
    width: 'auto',
    cursor: 'pointer',
  },
  inputSearch: {
    width: '450px'
  },
  navbarItems: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '1.5rem',
    marginLeft: '15px',
    cursor: 'pointer',
    color:'black'
  },
  searchInput: {
    marginLeft: '15px',
    padding: '5px',
    width:'100%'
  },
  candidatosContainer: {
    position: 'absolute', 
    zIndex: '1', 
    backgroundColor:'white'
  },
  candidatosList: {
    maxHeight: '250px',
    overflow: 'auto',
    width: '480px'
  },
  candidatoContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0',
    padding: '5px',
    borderRadius: '5px',
    backgroundColor: 'white',
    cursor: 'pointer',
  },
  fotoPerfil: {
    width: '60px',
    height: '60px',
    borderRadius: '5%',
    marginRight: '10px',
  },
  nombre: {
    fontSize: '16px',
  }
};

export default Navbar;
