import React, { useState } from 'react';
import Deemby from './imagenes/colmena.png';

const BottomMenu = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const deembyReturn = () => {
    window.location.href = 'https://deemby.org';
  };

  return (
    <div style={styles.bottomMenu}>
      <div style={styles.linksContainer}>
        <p style={styles.terms} onClick={openModal}>Terminos y Condiciones</p>
        <p style={styles.terms} onClick={openModal}>Ayuda</p>
        <p style={styles.terms} onClick={openModal}>Faqs</p>
      </div>
      <img src={Deemby} alt="InfoGobierno" style={styles.logo} onClick={() => deembyReturn()} />
      {modalOpen && (
        <div style={styles.modalOverlay} onClick={closeModal}>
          <div style={styles.modal}>
            <h2>Modal</h2>
            <p>Terminos y Condiciones.</p>
            <button onClick={closeModal}>Cerrar</button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  bottomMenu: {
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column', // Cambio a columna
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '10px 0', // Añadido espacio de relleno
  },
  logo: {
    width: '85px',
    height: '85px',
    cursor: 'pointer',
  },
  terms: {
    color: '#5D7CF9',
    textDecoration: 'underline',
    cursor: 'pointer',
    paddingTop: '5%',
    margin: '0 10px', // Añadido margen para separar los enlaces
  },
  linksContainer: {
    display: 'flex', // Contenedor flex para alinear los enlaces
    marginBottom: '10px', // Añadido margen inferior
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '5px',
    textAlign: 'center',
  },
};

export default BottomMenu;
