import React, { useState, useEffect } from 'react';

const ChatComponent = ({ messages, isVisible, setIsVisible }) => {
    const [newMessage, setNewMessage] = useState('');
    const [chat, setChat] = useState([]);
    const id_user = localStorage.getItem('id_user');
    const organizacion = localStorage.getItem('organizacion');

    useEffect(() => {
        goMessages(messages);
    }, [messages]);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const handleInputChange = (event) => {
        setNewMessage(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const data = {
            'type': 'SendMessage',
            'emisor': id_user,
            'receptor':id_user===messages.id_user_recepcion?messages.id_user_envio : messages.id_user_recepcion,
            'organizacion': organizacion>0?1:2,
            'message': newMessage
        };
        fetch('https://novomediaapp.azurewebsites.net/InfoGobiernoProv.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            if(data.success){
                goMessages(messages);
                setNewMessage('');
            }else{

            }
        })
        .catch(error => console.error('Error de red:', error));
    };

    const goMessages = (message) => {
        const data = {
            'type': 'GetMessageUser',
            'emisor': message.id_user_envio,
            'receptor': message.id_user_recepcion
        };
        fetch('https://novomediaapp.azurewebsites.net/InfoGobiernoProv.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            setChat(data.data);
        })
        .catch(error => console.error('Error de red:', error));
    };

    return (
        <div>
            {isVisible && (
                <div style={styles.chatContainer}>
                    <button style={styles.closeButton} onClick={toggleVisibility}>X</button>
                    <div style={styles.chatMessages}>
                        {chat? 
                        chat.map((message, index) => (
                            <div key={index} style={message.id_user_envio !== id_user ? styles.receiverMessage : styles.senderMessage}>
                                {message.mensaje}
                            </div>
                        )):''}
                    </div>
                    <div style={styles.formContainer}>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                placeholder="Escribe tu mensaje..."
                                value={newMessage}
                                onChange={handleInputChange}
                                style={styles.inputField}
                            />
                            <button type="submit" style={styles.sendButton}>Enviar</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = {
    chatContainer: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        width: '300px',
        height: '300px',
        border: '1px solid #ccc',
        borderRadius: '5%',
        overflowY: 'auto',
        backgroundColor: 'white',
    },

    chatMessages: {
        flex: '1',
        padding: '10px',
        overflowY: 'auto',
    },

    formContainer: {
        padding: '10px',
        backgroundColor: '#f9f9f9',
    },

    senderMessage: {
        backgroundColor: '#e6f2ff',
        color: '#333',
        padding: '8px',
        marginBottom: '5px',
        borderRadius: '5px',
        float: 'right',
        clear: 'both',
    },
    receiverMessage: {
        backgroundColor: '#f0f0f0',
        color: '#333',
        padding: '8px',
        marginBottom: '5px',
        borderRadius: '5px',
        maxWidth: '70%',
        float: 'left',
        clear: 'both',
    },
    inputField: {
        flex: 1,
        padding: '8px',
        marginRight: '5px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    sendButton: {
        width: '60px',
        padding: '8px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#000000',
        color: '#fff',
        cursor: 'pointer',
    },
    closeButton: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
    },
};

export default ChatComponent;
