import React, { useEffect,useState } from 'react';
import feed from './imagenes/feed.png'
import StoryCarouselMob from './StoryCarouselMob';
import PersonListMob from './PersonListMob';

const MainMobile = (props) => {
  const [publicaciones, setPublicaciones] = useState([]);
  const id_user = localStorage.getItem('id_user');

  useEffect(() => {
    getPublicaciones(id_user);
  }, []);
  
  const getPublicaciones = (IdUser) => {
    const data = {
      'type':'GetPublicaciones',
      'IdUser':IdUser
    } 
    fetch('https://novomediaapp.azurewebsites.net/InfoGobiernoProv.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setPublicaciones(data.data);
          console.log(data.data);
        } 
      })
      .catch(error => console.error('Error de red:', error));
  }

  return (
    <>
      <div>
        <>
          <img src={feed} style={{width: '100%',paddingLeft:'30%',paddingRight:'30%'}}/>
          <div>
            <StoryCarouselMob publicaciones={publicaciones} getPublicaciones={getPublicaciones} deembys={props.deembys} getDeembysDiarios={props.getDeembysDiarios}/>
            <PersonListMob setFiltroRP={props.setFiltroRP} filtroRP={props.filtroRP} partido={props.partido} setFiltro={props.setFiltro}  setPartido={props.setPartido} setcandidato={props.setcandidato} filtro={props.filtro} filtroMr={props.filtroMr} setFiltroMr={props.setFiltroMr}/>
          </div>
        </>
      </div>
    </>
  );
};

export default MainMobile;
