import React, { useState, useEffect } from 'react';
import deembys from './imagenes/dem.png'
import seguidores from './imagenes/seguidores.png'
import { useNavigate } from 'react-router-dom'; 
import SidebarMobile from './SidebarMobile';
import Filtro from './Filtro'

const PersonList = ({setPartido,setFiltroRP, setcandidato,filtro,setFiltroMr,filtroMr,partido,setFiltro,filtroRP}) => {
  const [personas, setPersonas] = useState([]);
  const navigate = useNavigate(); 
  const UsuarioId = localStorage.getItem('id_user');

  useEffect(() => {
    if (filtro !== null && filtroMr !== null && partido !== null && filtroRP!== null) {
      getGobernantes(filtro);
    }
  }, [filtro,filtroMr,partido,filtroRP]);


  const vibe = () => {
    alert('El usuario esta inactivo');
  }

  const getGobernantes = (filtro) => {
    setPersonas([]);
    const data = {'type':'GetGobernantes','id_usuario':UsuarioId,filtro,filtroMr,partido,filtroRP}
    fetch('https://novomediaapp.azurewebsites.net/InfoGobiernoProv.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      setPersonas(data.data);
    })
    .catch(error => console.error('Error de red:', error));
  }  

  const seguir_Usuario = (idSeguir,status) => {
    alert('El usuario esta inactivo');
    /*const data = {
      'type':'Seguir_Usuario',
      'id_usuario':UsuarioId,
      'seguir':idSeguir,
      'status':status,
    }
    fetch('https://novomediaapp.azurewebsites.net/InfoGobiernoProv.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      getGobernantes();
    })
    .catch(error => console.error('Error de red:', error));*/
  }  

  const handleProfileClick = (candidato) => {
    setcandidato(candidato);
    navigate(`/Candidato`);
  };

  return (
    <>
      <br />
      <p style={{ fontSize: '20px' }}>Tus Gobernantes </p>
      <SidebarMobile setFiltro={setFiltro} filtro={filtro} deembys={deembys} filtroMr={filtroMr} setFiltroMr={setFiltroMr}/>
      {filtroMr===4||filtroMr===5?<Filtro filtroRP={filtroRP} filtroMr={filtroMr} filtro={filtro} setFiltroMr={setFiltroRP} setPartido={setPartido}/>:''}
      <div style={styles.container}>
        {personas.map((persona) => (
          <div key={persona.usuario_id} style={styles.personaContainer}>
            <div style={{ ...styles.imageContainer, flex: '0 0 30%' }}>
              <img src={persona.foto} alt="Imagen de persona" style={styles.image} onClick={() => handleProfileClick(persona)} />
            </div>
            <div style={{ ...styles.infoContainer, flex: '1' }}>
              <p style={styles.puesto}>{persona.puesto} -
                {persona.ejecutivo === '1' ? ' Ejecutivo'
                  : persona.legislativo === '1' ? ' Legislativo'
                    : persona.judicial === '1' ? ' Judicial'
                      : ' Gabinete'}
              </p>
              <div style={styles.iconsContainer}>
                
              </div>
              <p style={styles.nombre} onClick={() => handleProfileClick(persona)}>{persona.nombre}</p>
              <p style={styles.apellido} onClick={() => handleProfileClick(persona)}>{persona.a_paterno} {persona.a_materno}</p>
              <img src={seguidores} alt="Imagen de persona" style={{ width: '24px' }} /><span style={{ color: '#000000' }}>{persona.seguidores}</span>
              <img src={deembys} alt="Imagen de persona" style={{ width: '44px', paddingLeft: '3%' }} /><span style={{ color: '#000000' }}>{persona.seguidores}</span>
              <img src={persona.logo_partido} alt="Imagen de persona" style={{ width: '44px', paddingLeft: '3%'  }} />
              <div style={styles.iconsContainer}>
                {persona.siguiendo === '1' ?
                  <button style={styles.buttonSiguiendo} onClick={() => seguir_Usuario(persona.usuario_id, 0)}>Siguiendo</button>
                  :
                  <button style={styles.buttonSeguir} onClick={() => seguir_Usuario(persona.usuario_id, 1)}>Seguir</button>
                }
              </div>
            </div>
            
            <br />
          </div>
        ))}
      </div>
    </>
  );
};

const styles = {
  modalContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  leftPane: {
    width: '50%',
    height: '100%',
    padding:'3%' 
  },
  rightPane: {
    width: '50%',
    height: '100%',
    padding:'3%' 
  },
  container: {
    margin: '0 20px', 
    maxHeight: '400px', 
    overflowY: 'auto',
  },
  personaContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  imageContainer: {
    width: '100px',
    height: '100px',
    borderRadius:'50%',
    marginRight: '10px',
    overflow: 'hidden', 
  },
  
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor:'pointer'
  },
  infoContainer: {
    flex: 1,
    cursor:'pointer',
  },
  puesto: {
    margin: 0,
    color: '#aaa',
  },
  nombre: {
    margin: 0,
    fontWeight: 'bold',
  },
  apellido: {
    margin: 0,
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    cursor: 'pointer',
    fontSize: '1.5rem',
    color: '#5D7CF9',
  },
  buttonSeguir: {
    width: '100%',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: '#000000',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    marginRight: '10px',
  },
  buttonVibe: {
    width: '100%',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: '#000000',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    marginRight: '10px',
  },
  buttonComparar: {
    width: '100%',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: 'white',
    color: '#000000',
    border: '1px solid #000000',
    cursor: 'pointer',
    marginRight: '10px',
  },
  buttonSiguiendo: {
    width: '100%',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: '#000000',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    marginRight: '10px',
  },
  iconAmigos: {
    marginLeft: '10px',
    width: '24px',
    height: '24px',
    backgroundColor: '#ccc',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default PersonList;
