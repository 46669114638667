import React, { useState,useEffect } from 'react';
import Sidebar from './Sidebar';

const ContactList = ({SetMessage,setIsVisible}) => {
  const id_user = localStorage.getItem('id_user');
  const [contacts, setContacts] = useState([]);
  

  useEffect(() => {
    getMessages();
  }, []);
   
  const renderLastMessageTime = (lastMessageDate) => {
    const currentDate = new Date();
    const messageDate = new Date(lastMessageDate);

    const timeDifference = currentDate.getTime() - messageDate.getTime();

    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    const hoursDifference = Math.floor(minutesDifference / 60);

    if (hoursDifference > 0) {
        return `${hoursDifference*-1} hora${hoursDifference*-1!== 1 ? 's' : ''} atrás`;
    } else {
        return `${minutesDifference*-1} minuto${minutesDifference*-1 !== 1 ? 's' : ''} atrás`;
    }
};

  const goMessages = (message) => {
    SetMessage(message);
    setIsVisible(true);
  }
  
  const getMessages = () => {
    const data = {'type':'GetMessages','id_usuario':id_user}
    fetch('https://novomediaapp.azurewebsites.net/InfoGobiernoProv.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      setContacts(data.data);
    })
    .catch(error => console.error('Error de red:', error));
  }  

  const styles = {
    contactListContainer: {
      width: '300px', // Ancho del contenedor de la lista de contactos
    },
    contactList: {
      listStyleType: 'none',
      padding: '0',
    },
    contactItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '15px',
      cursor:'pointer'
    },
    profilePicture: {
      width: '50px', // Ancho de la foto de perfil
      height: '50px', // Altura de la foto de perfil
      borderRadius: '50%', // Borde redondeado para la foto de perfil
      marginRight: '10px', // Margen a la derecha de la foto de perfil
    },
    contactDetails: {
      flex: '1', // El contenido del contacto se expande para llenar el espacio restante
    },
    contactName: {
      fontWeight: 'bold',
    },
    lastMessage: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    messageTime: {
      fontSize: '12px', // Tamaño de fuente para el tiempo del mensaje
      color: '#777', // Color de texto para el tiempo del mensaje
    },
    container: {
      display: 'grid',
      gridTemplateColumns: '30% 70%',
    },
  };



  return (
    <div style={styles.container}>
      <div style={styles.sidebar}>
        <Sidebar/>
      </div>
      <div style={styles.carousel}>
        <div style={styles.contactListContainer}>
          <ul style={styles.contactList}>
            {contacts.map(contact => (
              <li key={contact.id} style={styles.contactItem} onClick={() => goMessages(contact)}>
                <img src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3dq7CO4moMLE6qkrUCgR8M0F2fEMuPiJLSdkf2XtblQ&s`} alt={`Profile of ${contact.name}`} style={styles.profilePicture} />
                <div style={styles.contactDetails}>
                  <p style={styles.contactName}>{contact.id_user_envio===id_user? contact.nombre_recepcion:contact.nombre_envio}</p>
                  <div style={styles.lastMessage}>
                    <p>{contact.mensaje}</p>
                    <p style={styles.messageTime}>{renderLastMessageTime(contact.fecha)}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    
  );
};

export default ContactList;
