import React, { useEffect,useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopBarDes from './TopBarDes';
import MessageDes from './MessageDes';
import Main from './Main';
import MainMobile from './MainMobile';
import NewPost from './NewPost';
import Candidato from './Candidato';
import CandidatoMobile from './CandidatoMobile';
import Top10 from './Top10';
import Top10Mobile from './Top10Mobile';
import ChatComponent from './ChatComponent';
import NavBarBaja from './NavBarBaja';

const App = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [candidato, setcandidato] = useState([]);
  const [filtro, setFiltro] = useState(1);
  const [filtroMr, setFiltroMr] = useState(1);
  const [filtroRP, setFiltroRP] = useState(0);
  const [partido, setPartido] = useState('Todos');
  const [messages, SetMessage] = useState([]);
  const [isValidSession, setIsValidSession] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [deembys, setDeembys] = useState([]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    setIsDesktop(mediaQuery.matches);
    
    const urlParams = new URLSearchParams(window.location.search);
    const sessionToken = urlParams.get('sessionToken');
    const token = localStorage.getItem('token');
    const id_user = localStorage.getItem('id_user');
    
    if (sessionToken) {
      getSesion(sessionToken);
    }else{
      if(token && id_user){
        setIsValidSession(true);  
      } else {
        localStorage.setItem('token', '');
        localStorage.setItem('id_user', '');
        window.location.href = 'https://deemby.org/';
      }
    }
    
    getDeembysDiarios();
  }, []);

  const getSesion = (token) => {
    localStorage.setItem('token', '');
    localStorage.setItem('id_user', '');
    localStorage.setItem('organizacion', '');

    const data = {'type':'GetSesion',token}
    fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      if(data.success){
        localStorage.setItem('token', data.token);
        localStorage.setItem('id_user', data.usuario_id);
        localStorage.setItem('organizacion', data.organizacion_id);
        setIsValidSession(true);
      }else{
        setIsValidSession(false);
      }
      
    })
    .catch(error => console.error('Error de red:', error));
  }  

  const getDeembysDiarios = () => {
    const id_user = localStorage.getItem('id_user');
    const data = { 'type': 'GetDemmbysToDay',id_user }
    fetch('https://novomediaapp.azurewebsites.net/InfoGobiernoProv.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        setDeembys(data.data);
      })
      .catch(error => console.error('Error de red:', error));
  }

  return (
    <div style={{paddingLeft:'10%',paddingRight:'5%'}}>
        {isValidSession?
          isDesktop?
          (<Router>
            <TopBarDes setcandidato={setcandidato} isDesktop={isDesktop}/>
            <Routes>
              <Route path="/" element={<Main setFiltroRP={setFiltroRP} filtroRP={filtroRP} isDesktop = {isDesktop} getDeembysDiarios={getDeembysDiarios} deembys={deembys} setPartido={setPartido} partido={partido} setcandidato={setcandidato} filtro={filtro} setFiltro={setFiltro} filtroMr={filtroMr} setFiltroMr={setFiltroMr}/>}/>
              <Route path="/Message" element={<MessageDes SetMessage={SetMessage} setIsVisible={setIsVisible}/>} />
              <Route path="/NewPost" element={<NewPost />} />
              <Route path="/Candidato" element={<Candidato isDesktop = {isDesktop} candidato={candidato} deembys={deembys} filtro={filtro} setFiltro={setFiltro} getDeembysDiarios={getDeembysDiarios}/>} />
              <Route path="/Top10" element={<Top10 deembys={deembys} setcandidato={setcandidato} filtro={filtro} setFiltro={setFiltro}/>} />
            </Routes>
            <NavBarBaja setcandidato={setcandidato} isDesktop={isDesktop}/>
            <ChatComponent messages={messages} isVisible={isVisible} setIsVisible={setIsVisible} />
          </Router>)
          :
          (<Router>
            <TopBarDes setcandidato={setcandidato} isDesktop={isDesktop}/>
            <Routes>
              <Route path="/" element={<MainMobile setFiltroRP={setFiltroRP} filtroRP={filtroRP} isDesktop = {isDesktop} getDeembysDiarios={getDeembysDiarios} deembys={deembys} setPartido={setPartido} partido={partido} setcandidato={setcandidato} filtro={filtro} setFiltro={setFiltro} filtroMr={filtroMr} setFiltroMr={setFiltroMr}/>}/>
              <Route path="/NewPost" element={<NewPost />} />
              <Route path="/Candidato" element={<CandidatoMobile isDesktop = {isDesktop} candidato={candidato} deembys={deembys} filtro={filtro} setFiltro={setFiltro} getDeembysDiarios={getDeembysDiarios}/>} />
              <Route path="/Top10" element={<Top10Mobile deembys={deembys} setcandidato={setcandidato} filtro={filtro} setFiltro={setFiltro}/>} />
            </Routes>
            <NavBarBaja setcandidato={setcandidato} isDesktop={isDesktop}/>
            <ChatComponent messages={messages} isVisible={isVisible} setIsVisible={setIsVisible} />
          </Router>)
        :<p>La sesión no es válida. Serás redirigido a otra página.</p>
        }
    </div>
  );
};

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    paddingTop:'2%'
  },
  sidebar: {
    backgroundColor: '#fff',
  }
};


export default App;
